import React, { useEffect, useState } from "react";
import CustomSidebar from "./CustomSidebar";
import CustomHeader from "./CustomHeader";
import PageWrapper from "./PageWrapper";
import CustomFooter from "./CustomFooter";
import { useGetMe } from "../../genericQueries";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  doesUserHavePermission,
  errorSweetAlert,
  logoutUser,
} from "../../helpers/helperFunctions";
import IsLoading from "../generic/IsLoading";
import { twMerge } from "tailwind-merge";

const MainLayout = ({
  children,
  title,
  parentPath,
  runProtection,
  permission,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { pathname } = useLocation();
  const [currentPath, setCurrentPath] = useState(pathname);
  useGetMe(
    runProtection && !doesUserHavePermission(permission) ? null : pathname
  );
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  // useEffect(() => {
  //   if (runProtection && !doesUserHavePermission(permission)) {
  //     errorSweetAlert("You do not have permission to access this route");
  //     setTimeout(() => {
  //       navigate(-1);
  //     }, 2000);
  //   }
  // }, [pathname, user]);

  return (
    <PageWrapper title={title}>
      <div className="h-screen w-screen flex items-stretc text-black">
        {/* sidebar */}
        <CustomSidebar
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          parentPath={parentPath}
        />

        {/* right layout */}
        <div className="flex-1 flex flex-col">
          <CustomHeader
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
          <div className="flex-1 overflow-x-hidden overflow-y-auto scrollbar-thin">
            {runProtection && !doesUserHavePermission(permission) ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={"/images/logo.webp"}
                  alt=""
                  className={twMerge(
                    " w-[250px] object-scale-down m-1 mx-auto"
                  )}
                />
              </div>
            ) : (
              children
            )}
          </div>
          {/* <CustomFooter /> */}
        </div>
      </div>
    </PageWrapper>
  );
};

export default MainLayout;
