import React, { useEffect } from "react";
// import { errorToast } from "../../helpers/helperFunctions";

const IsError = ({ message = "Server error: Cannot perform this request" }) => {
  // useEffect(() => {
  //   errorToast(message);
  // }, []);
  return <div className="py-7 px-3">{message}</div>;
};

export default IsError;
