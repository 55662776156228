import React from "react";
import { twMerge } from "tailwind-merge";

const IsNoData = ({
  message = "No data found. Please add more, to populate the list.",
  className = "",
}) => {
  return (
    <div className={twMerge("py-7 px-3 border-l-4 border-main", className)}>
      {message}
    </div>
  );
};

export default IsNoData;
