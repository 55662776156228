import {
  FaBookOpen,
  FaCodeBranch,
  FaGauge,
  FaList,
  FaGears,
  FaMoneyBill,
  FaRegRectangleList,
  FaUsers,
  FaUsersRectangle,
} from "react-icons/fa6";

const menus = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: <FaGauge className="text-xl" />,
    permission: "66aa2d71a37e718c79d58ba6",
  },
  {
    title: "Branch Management",
    link: "/branch-management",
    icon: <FaCodeBranch className="text-xl" />,
    permission: "66aa2d71a37e718c79d58ba7",
  },
  {
    title: "Customer Management",
    link: "/customer-management",
    icon: <FaUsersRectangle className="text-xl" />,
    permission: "66aa2d71a37e718c79d58ba8",
  },
  {
    title: "User Management",
    link: "/user-management",
    icon: <FaUsers className="text-xl" />,
    permission: "66aa2d71a37e718c79d58ba9",
  },
  {
    title: "Bookings Management",
    link: "/booking-management",
    icon: <FaBookOpen className="text-xl" />,
    permission: "66aa2d71a37e718c79d58baa",
  },
  {
    title: "Payments",
    link: "/payments",
    icon: <FaMoneyBill className="text-xl" />,
    permission: "66aa2d71a37e718c79d58bab",
  },
  {
    title: "Reports",
    link: "/reports",
    icon: <FaRegRectangleList className="text-xl" />,
    permission: "66aa2d71a37e718c79d58bac",
  },
  {
    icon: <FaGears className="text-xl" />,
    permission: "66aa2d71a37e718c79d58bad",
    title: "Roles and Permissions",
    link: "/roles-and-permissions",
  },
  // {
  //   icon: <FaList className="text-xl" />,
  //   permission: "66e92c676c00a30007814b0e",
  //   title: "Audit Logs",
  //   link: "/audit-logs",
  // },
];

export default menus;
