import { useQuery, useMutation } from "@tanstack/react-query"
import axiosConfig from "./http-common"
import { store } from "./redux/store"
import { loginUserAction } from "./redux/slices/authSlice"
import { backendUrl } from "./helpers/constants/system"
import { setBranchAction } from "./redux/slices/branchSlice"


export const useGetMe = (pathname) => useQuery({
  retry: 1,
  queryKey: ["getMe", { pathname }],
  queryFn: async () => {
    try {
      const token = store.getState().auth?.token
      const data = await axiosConfig.get("/auth/getMe")
      store.dispatch(loginUserAction({
        accessToken: token,
        user: {
          ...data?.user,
          subscriptionPayments: data?.subscriptionPayments
        }
      }))


      return data
    } catch (error) {

    }
  },
  enabled: !!store.getState().auth?.user
})



export const useGetActiveBranch = (isFetch) => useQuery({
  queryKey: ["getActiveBranch"],
  queryFn: async () => {
    try {
      const data = await axiosConfig.get("/auth/getMe")

      const branch = data?.user?.branch?.find(branch => branch?._id === store.getState().branch?.branch?._id) || data?.user?.branch[0]

      store.dispatch(
        setBranchAction(
          branch ? {
            ...branch,
            label: `${branch?.name} (${branch?.address?.city})`,
            value: branch?._id,
          }
            : null
        )
      );
    } catch (error) {

    }
  },
  enabled: !!isFetch
})

export const useGetRoles = () => useQuery({
  retry: 1,
  queryKey: ["getRoles"],
  queryFn: async () => await axiosConfig.get("/role")
})


export const useGetBranches = () => useQuery({
  retry: 1,
  queryKey: ["getBranches"],
  queryFn: async () => await axiosConfig.get("/branch")
})


export const useGetBusinessBranches = () => useQuery({
  retry: 1,
  queryKey: ["getBusinessBranches", { branch: store.getState().auth.user?.business?._id }],
  queryFn: async () => await axiosConfig.get(`/branch/business/${store.getState().auth.user?.business?._id}/branches`)
})



export const useGetBusinessRoles = () => useQuery({
  retry: 1,
  queryKey: ["getBusinessRoles", { business: store.getState().auth.user?.business?._id }],
  queryFn: async () => (await axiosConfig.get(`/role/business/${store.getState().auth.user?.business?._id}`))
})


export const useGetAllServices = () => useQuery({
  queryKey: ["getAllServices"],
  queryFn: async () => await axiosConfig.get("/service", {
    baseURL: `${backendUrl}/common`
  })
})



export const useGetCarTypes = () => useQuery({
  queryKey: ["getAllCarTypes"],
  queryFn: async () => await axiosConfig.get("/carType")
})


export const useGetServicesByCarType = ({ type, branchId }) => useQuery({
  queryKey: ["getServicesByCarType", { type, branchId }],
  queryFn: async () => await axiosConfig.get(`/carType/branch/${branchId}/carType/${type}/services`),
  enabled: !!type && !!branchId
})



export const useGetBranchNotifications = () => useQuery({
  queryKey: ["getBranchNotifications"],
  queryFn: async () => await axiosConfig.get(`/notification/branchNotifications/${store.getState().branch?.branch?._id}`),
  enabled: !!store.getState().branch?.branch?._id
})

export const useUpdateBranchNotifications = () => useMutation({
  mutationKey: ["updateBranchNotifications"],
  mutationFn: async (formData) => await axiosConfig.put(`/notification/${formData?.id}`, formData),
})



export const useGetAllVehcileMakes = () => useQuery({
  queryKey: ["getAllVehicleMakes"],
  queryFn: async () => await axiosConfig.get("/cars/makes", {
    baseURL: `${backendUrl}/common`
  })
})


export const useGetMakeBrands = (makeId) => useQuery({
  queryKey: ["getMakeBrands", { makeId }],
  queryFn: async () => await axiosConfig.get(`/cars/brand/${makeId}`, {
    baseURL: `${backendUrl}/common`
  }),
  enabled: !!makeId
})