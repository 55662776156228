import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { systemName } from "../../helpers/constants/system";
import {
  FaArrowRightLong,
  FaArrowsTurnRight,
  FaBarsStaggered,
  FaBuilding,
  FaChevronDown,
  FaClock,
} from "react-icons/fa6";
import { ControlledMenu, Menu, useClick } from "@szhsin/react-menu";
import { lockScreen, logoutUser, uuidV4 } from "../../helpers/helperFunctions";
import { FaBell, FaLock, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import moment from "moment";
import CustomReactSelect from "../generic/CustomReactSelect";
import { useDispatch, useSelector } from "react-redux";
import { setBranchAction } from "../../redux/slices/branchSlice";
import {
  useGetBranchNotifications,
  useUpdateBranchNotifications,
} from "../../genericQueries";
import { socket } from "../../socket";
import IsError from "../generic/IsError";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import IsNoData from "../generic/IsNoData";

const CustomHeader = ({ isCollapsed, setIsCollapsed }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { branch } = useSelector((state) => state.branch);
  const getBranchNotifications = useGetBranchNotifications();
  const updateBranchNotification = useUpdateBranchNotifications();
  const queryClient = useQueryClient();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const anchorProps = useClick(isOpen, setOpen);

  useEffect(() => {
    socket.connect();
    socket.on(branch?._id, (data) => {
      queryClient.invalidateQueries(["getBranchNotifications"]);
    });
  }, []);

  const handleReadBooking = async (id) => {
    try {
      await updateBranchNotification.mutateAsync(
        {
          id,
          isRead: true,
        },
        {
          onError: (err) => {
            toast.error(
              err?.response?.data?.message ||
                "Server error: cannot update notification"
            );
          },
          onSuccess: () => {
            queryClient.invalidateQueries(["getBranchNotifications"]);
          },
        }
      );
    } catch (error) {}
  };

  return (
    <div className="bg-white">
      {(!user?.business?.subscription ||
        user?.business?.subscription?.status === "inactive") && (
        <div className="text-customRed p-1 px-3 text-right w-full font-semibold border-b border-main">
          {user?.business?.subscription?.status === "inactive"
            ? "Your subscription in active"
            : !user?.business?.subscription &&
              "You have no active subscription"}
        </div>
      )}
      <div className="text-main bg-white p-2 flex items-center justify-between px-5">
        <div className="flex items-center gap-5">
          <button className="" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? (
              <FaArrowRightLong className="text-xl" />
            ) : (
              <FaBarsStaggered className="text-xl" />
            )}
          </button>
          <Link to={"/dashboard"} className="font-bold">
            {systemName}
          </Link>
        </div>
        <div className="flex items-center gap-7">
          {user && (
            <>
              <h6 className="flex items-center gap-2 font-semibold text-customPrimary">
                <FaBuilding /> <span>{user?.business?.name}</span>
              </h6>

              <Menu
                menuButton={
                  <button className="border-0 outline-0 flex items-center gap-2 w-[300px]">
                    <span>{branch ? branch?.label : "Select branch"}</span>{" "}
                    <FaChevronDown />
                  </button>
                }
                transition
              >
                <div className="w-[270px] min-h-[150px] p-2">
                  <CustomReactSelect
                    placeholder={"Choose a branch"}
                    label={"Choose a branch"}
                    value={branch}
                    onChange={(option) => dispatch(setBranchAction(option))}
                    options={user?.branch?.map((bran) => ({
                      ...bran,
                      label: `${bran?.name} (${bran?.address?.city})`,
                      value: bran?._id,
                    }))}
                  />
                  <p className="mt-5 text-xs">
                    <b>Note:</b> If you change a branch the data you see and
                    change will only affect that branch. The reason for this
                    design is for efficiency and ease. Imagine seeing data from
                    all at once. Oo boy, that request will take forever to load.
                  </p>
                </div>
              </Menu>

              <button
                type="button"
                ref={ref}
                {...anchorProps}
                className="border-0 outline-0 relative"
              >
                <div className="absolute -top-2 -right-3 text-xs p-1 w-[20px] h-[20px] rounded-full bg-orange-500 text-white font-bold flex items-center justify-center">
                  {getBranchNotifications.data?.data?.length || 0}
                </div>
                <FaBell className="text-3xl" />
              </button>
              <ControlledMenu
                state={isOpen ? "open" : "closed"}
                anchorRef={ref}
                onClose={() => setOpen(false)}
                transition
              >
                <div
                  className={
                    "w-[300px] rounded-md overflow-hidden flex flex-col"
                  }
                >
                  <div className="bg-secondary flex items-center justify-between p-3">
                    <h6 className="text-white">Bookings</h6>
                    <div className="flex items-center gap-2 bg-white text-black p-1 px-2 rounded-md text-sm font-semibold">
                      {getBranchNotifications.data?.data?.length || 0} New
                    </div>
                  </div>
                  <div className="flex flex-col">
                    {
                      // getBranchNotifications.isPending ? (
                      //   <div className="p-3 flex items-center justify-center">
                      //     <CustomSpinner />
                      //   </div>
                      // ) :
                      getBranchNotifications.isError ? (
                        <IsError
                          message={
                            getBranchNotifications.error?.response?.data
                              ?.message ||
                            "Server error: cannot fetch notifications"
                          }
                        />
                      ) : getBranchNotifications.data?.data?.length === 0 ? (
                        <IsNoData message="No new bookings" />
                      ) : (
                        getBranchNotifications.data?.data?.map(
                          (notification) => (
                            <button
                              onClick={() => {
                                handleReadBooking(notification?._id);
                                setOpen(false);
                                navigate(`/booking-management/details`, {
                                  state: {
                                    _id: notification?.bookingId,
                                  },
                                });
                              }}
                              className="w-full flex flex-col gap-2 p-4 font-semibold hover:bg-gray-100"
                              key={notification._id}
                            >
                              <p>{notification.title || "N/A"}</p>
                              <p className="text-xs flex items-center gap-2 text-gray-500">
                                <FaClock />{" "}
                                {moment(notification.createdAt).fromNow()}
                              </p>
                            </button>
                          )
                        )
                      )
                    }
                  </div>
                  <div className="flex items-center justify-center p-2">
                    <button
                      onClick={() => navigate("/booking-management")}
                      className="text-green-800 bg-green-200 p-2 flex items-center gap-2 text-sm "
                    >
                      View All bookings <FaArrowRightLong />
                    </button>
                  </div>
                </div>
              </ControlledMenu>

              <Menu
                menuButton={
                  <button className="border-0 outline-0">
                    <img
                      src="/images/profile.png"
                      className="w-10 h-10 object-scale-down rounded-full"
                      alt="PROFILE"
                    />
                  </button>
                }
                shift={12}
                transition
              >
                <div className={"w-[200px] p-2 flex flex-col gap-2"}>
                  <p className="text-sm">
                    Welcome{" "}
                    <span className="font-semibold">{`${user?.firstName} ${user?.lastName}`}</span>
                  </p>
                  <div>
                    <button
                      onClick={() => navigate("/profile")}
                      className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                    >
                      <FaUserCircle className="text-xl" />{" "}
                      <span>Your Profile</span>
                    </button>
                    <button
                      onClick={() => navigate("/subscription")}
                      className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                    >
                      <FaArrowsTurnRight className="text-xl" />{" "}
                      <span>Subscription</span>
                    </button>
                    <button
                      onClick={() => logoutUser(navigate)}
                      className="flex items-center gap-3 w-full py-2 hover:bg-gray-100 rounded-md px-1 text-main"
                    >
                      <FaSignOutAlt className="text-xl" /> <span>Sign Out</span>
                    </button>
                  </div>
                </div>
              </Menu>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;
