import Swal from "sweetalert2"
import { v4 } from "uuid"
import { mainColor } from "./constants/colors"
import toast from "react-hot-toast"
import ErrorGif from "../assets/icons/error.png"
import SuccessGif from "../assets/icons/success.png"
import { FaX } from "react-icons/fa6"
import { store } from "../redux/store"
import { logoutUserAction } from "../redux/slices/authSlice"
import moment from "moment";

export const convertMinutesToReadableTime = (minutes) => {
  const duration = moment.duration(minutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const mins = duration.minutes();
  return `${hours}h ${mins}m`;
};

export const uuidV4 = () => v4()


// File types

export const findFileKind = (file) => {
  const fileName = file?.name;

  if (fileName?.includes(".pdf")) {
    return "PDF file"
  } else if (fileName?.includes(".docx") || fileName?.includes(".doc")) {
    return "Word file"
  } else if (fileName?.includes(".xlsx") || fileName?.includes(".xls")) {
    return "Excel"
  } else if (fileName?.includes(".csv")) {
    return "CSV file"
  } else if (fileName?.includes(".ppt") || fileName?.includes(".pptx")) {
    return "Powerpoint file"
  } else if (fileName?.includes(".sql")) {
    return "SQL file"
  } else if (fileName?.match(/\.(jpeg|jpg|gif|png)$/)) {
    return "Image file"
  }
  else if (fileName?.match(/\.(mp4|avi|mkv)$/)) {
    return "Video file"
  }
  else return "Text file"

}


// file application type
export const getFileApplicationType = (fileName) => {
  if (fileName?.includes(".doc"))
    return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  else if (fileName?.includes(".xls"))
    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  else if (fileName?.includes(".csv")) return "text/csv";
  else return "application/pdf";
};

// toast and alerts


export const confirmationSweetAlert = async () => await Swal.fire({
  title: "Are you sure?",
  text: "Click yes to continue. Otherwise cancel.",
  confirmButtonColor: "#ff0000",
  confirmButtonText: "Yes",
  showConfirmButton: true,
  showCancelButton: true,
  cancelButtonColor: mainColor,
})



export const errorSweetAlert = (message, options = {}) => {
  Swal.fire({
    icon: "error",
    title: "Oops!",
    text: message || "Server error",
    confirmButtonColor: mainColor,
    confirmButtonText: "Okay",
    ...options

  })
}

export const errorToast = (message, options = {}) => {

  toast(t => (
    <div className="w-[200px] flex flex-col gap-2 z-[1000000000]">
      <div className="flex items-center gap-2 justify-between">
        <p className="text-xs font-semibold">Oops!</p>
        <button onClick={() => toast.dismiss(t.id)} className="bg-transparent p-2 border border-gray-300">
          <FaX className="text-sm" />
        </button>
      </div>
      <div className="flex items-center gap-2 w-full">
        <img src={ErrorGif} alt="" className="w-[35px] object-scale-down" />
        <p className="flex-1 text-xs">{message}</p>
      </div>
    </div>
  ), {
    duration: 5000,
    style: {
      marginTop: 24
    },
    ...options
  })
}


export const successToast = (message, options = {}) => toast(t => (
  <div className="w-[200px] flex flex-col gap-2 z-[1000000000]">
    <div className="flex items-center gap-2 justify-between">
      <p className="text-xs font-semibold">Yep!</p>
      <button onClick={() => toast.dismiss(t.id)} className="bg-transparent p-2 border border-gray-300">
        <FaX className="text-sm" />
      </button>
    </div>
    <div className="flex items-center gap-2 w-full">
      <img src={SuccessGif} alt="" className="w-[35px] object-scale-down" />
      <p className="flex-1 text-xs">{message}</p>
    </div>
  </div>
), {
  duration: 5000,
  style: {
    marginTop: 24
  },
  ...options
})


// auth helper
export const logoutUser = (navigate) => {
  store.dispatch(logoutUserAction());
  navigate("/")
}

export const lockScreen = (navigate) => {
  const { user } = store.getState().auth;
  const userCopy = { ...user }
  store.dispatch(logoutUserAction());
  navigate("/lock-screen", {
    state: userCopy
  })

}



export const doesUserHavePermission = (permission) => store.getState().auth?.user?.role?.permissions?.find(per => per === permission) ? true : false