import axios from 'axios';
import { backendUrl } from './helpers/constants/system';
import { store } from './redux/store';
import { logoutUserAction } from './redux/slices/authSlice';
import { errorSweetAlert } from './helpers/helperFunctions';

/**
 * THANK YOU CHATGPT
 */

// Create a new axios instance
const axiosConfig = axios.create({
  baseURL: backendUrl + "/back", // Set your API base URL
  headers: {
    'Content-Type': 'application/json', // Default content type
  },

});

// Request interceptor for adding authorization token or any other request manipulation
axiosConfig.interceptors.request.use(
  config => {
    // Add logic here to manipulate request config if needed, like adding authorization token
    const { token } = store.getState().auth

    config.headers.Authorization = `Bearer ${token || ""}`;
    return config;
  },
  error => {
    // Handle request error
    return Promise.reject(error);
  }
);

const notAuthorizedMessages = [
  "No token provided", "Failed to authenticate token", "You are not authorized to use our platform", "You are not authorized to perform this request"]

// Response interceptor for handling response errors globally
axiosConfig.interceptors.response.use(
  response => {
    // Return response data
    return response.data;
  },
  error => {
    // Handle response errors
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response error:', error.response.status, error.response.data);

      if (notAuthorizedMessages.includes(error.response?.data?.message)) {
        store.dispatch(logoutUserAction())
        errorSweetAlert(error.response?.data?.message || "Session expred. Please login again.`", {
          showConfirmButton: false
        })
        setTimeout(() => {
          window.location.href = "/"
        }, [1000])
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
    }
    return Promise.reject({
      ...error,
      response: {
        ...error?.response,
        data: {
          ...error.response?.data,
          message: error?.response?.data?.message?.message ? error?.response?.data?.message?.message : error?.response?.data?.message || "Server error: please contact system admin"
        }
      }
    }); // Return Promise.reject() to propagate the error further
  }
);

export default axiosConfig;