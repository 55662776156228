import React, { Suspense } from "react";
import MainLayout from "./components/layout/MainLayout";
import routes from "./routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-modern-drawer/dist/index.css";
import "react-responsive-modal/styles.css";
import "react-quill/dist/quill.snow.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { v4 as uuidV4 } from "uuid";
import PageWrapper from "./components/layout/PageWrapper";
import { twMerge } from "tailwind-merge";
import { socket } from "./socket";
import { useEffect } from "react";
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, idx) => (
          <Route
            key={`route-${idx}-${route.path}`}
            path={route.path}
            element={
              route.isProtected ? (
                <MainLayout {...route}>
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          src={"/images/logo.webp"}
                          alt=""
                          className={twMerge(
                            " w-[250px] object-scale-down m-1 mx-auto"
                          )}
                        />
                      </div>
                    }
                  >
                    {<route.element {...route} />}
                  </Suspense>
                </MainLayout>
              ) : (
                <PageWrapper title={route.title}>
                  <Suspense
                    fallback={
                      <div className="flex items-center justify-center w-full h-screen">
                        <img
                          src={"/images/logo.webp"}
                          alt=""
                          className={twMerge(
                            " w-[250px] object-scale-down m-1 mx-auto"
                          )}
                        />
                      </div>
                    }
                  >
                    {<route.element {...route} />}
                  </Suspense>
                </PageWrapper>
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default App;
